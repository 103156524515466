import {
  formatDistanceStrict,
  differenceInDays,
  formatDistance,
} from "date-fns";
import { fromZonedTime } from "date-fns-tz";

export const newUTCDate = (dateTime: string | number | Date): Date => {
  return fromZonedTime(dateTime, "UTC");
};

const MS_IN_MINUTE = 60 * 1000;
export const getTimeAgo = (time: Date, strict: boolean = false): string => {
  const now = Date.now();
  const ago = time.getTime();

  if (Math.abs(now - ago) <= MS_IN_MINUTE) {
    return "just now";
  }

  if (strict)
    return formatDistanceStrict(ago, now, {
      addSuffix: true,
    });
  return formatDistance(ago, now, {
    addSuffix: true,
  });
};

// Returns string 'X days ago / In X days'
export const getDaysAgo = (time: Date): string => {
  return formatDistanceStrict(time, new Date(), {
    unit: "day",
  });
};

export const getDaysNumbers = (time: Date): number => {
  return differenceInDays(time, new Date());
};

export const beforePresent = (time: Date) => time < new Date();
